@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');

.scroll-bar {
	overflow-x: visible;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #ffffffcc #cccccccc;
}
*::-webkit-scrollbar {
	height: 0.8rem;
	max-height: 8px;
	width: 0.5rem;
	max-width: 6px;
}
*::-webkit-scrollbar-track {
	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
	background: #efefef66;
	border-radius: 0.4rem;
}
*::-webkit-scrollbar-thumb {
	background: #cccccccc;
	border-radius: 0.4rem;
	border: 1px solid #9999;
}
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	& svg {
		animation: rotate;
		animation-duration: 0.8s;
		animation-iteration-count: infinite;
		transform-origin: 49.5% 56%;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;

		@keyframes rotate {
			0% {
				transform: rotate(0deg) scale(1);
			}
			50% {
				transform: rotate(0deg) scale(0.9);
			}
			100% {
				transform: rotate(360deg) scale(1);
			}
		}
	}
}
* {
	font-family: Noto Sans JP !important;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	overflow: hidden;
	height: 100vh;
}
a {
	color: #004cff;
	text-decoration: none;
	text-underline-offset: 1px;
	transition: all 200ms;
	&:hover {
		cursor: pointer;
		color: #0093ff;
		text-decoration: underline;
	}
}
p {
	padding: 0;
	margin: 0;
}
img {
	width: 100%;
	height: auto;
	object-fit: cover;
}
.nav {
	text-decoration: none;
	transition: all 100ms;
	padding: 4px 16px 4px 8px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
	color: #fff;
	&:hover {
		text-decoration: none;
		color: #000000;
		background-color: #ffffff;
	}
	&--active {
		color: #000000;
		background-color: #ffffff;
		text-shadow: 1px 1px 1px #aaa;
		svg {
			filter: drop-shadow(1px 1px 1px currentColor);
		}
	}
	&__child {
		box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
		padding-left: 2rem;
	}
	&__parent {
		background: #fff3;
	}
}
.r-center {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.row-selected {
	border: 1px solid #004cff;
}

[data-editable] {
	position: relative;
	border-top: 1px dotted #aaa;
	& * {
		transition: transform 150ms;
	}
	& > [data-block='left-actions']:last-child {
		.left-c-btn {
			top: 0px;
			transform: translate(0, -50%);
		}
		.left-add-row {
			top: 0px;
			bottom: unset;
			transform: translate(0, -20px);
			left: calc(100% + 40px);
		}
	}
	.s-full {
		content: '';
		position: fixed;
		z-index: 2;
		display: none;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}
}
#edit-area {
	[data-block='row'] {
		min-height: 130px;
	}
}
[data-block='row'] {
	position: relative;
	border-bottom: 1px dotted #aaa;
	& [data-block='cell'] {
		padding-bottom: 50px;
		&:not(:first-child) {
			border-left: 1px dotted #aaa;
		}
		&.focus {
			outline: 2px dashed #00ded7;
		}
		&:hover {
			outline: 1px dotted #00ded7;
		}
		& [data-type] {
			&.focus {
				outline: 2px dashed #00ded7;
			}
			&:hover {
				outline: 1px dotted #00ded7;
			}
		}
		& [data-type='text'] {
			padding: 1px;
		}
		& [data-type='child-text'] {
			&.focus {
				outline: 2px dashed #ce7b00;
			}
			&:hover {
				outline: 1px dotted #ce7b00;
			}
		}
	}
	&:hover {
		background: #dbdbdb38;
		&::after {
			content: '';
			position: absolute;
			z-index: 0;
			top: -2px;
			left: 0;
			width: 100%;
			height: 2px;
			transform: translateY(-100%);
			background-color: #77788087;
			display: block;
		}
		&::before {
			content: '';
			position: absolute;
			z-index: 0;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 2px;
			transform: translateY(100%);
			background-color: #77788087;
			display: block;
		}
		.row-action {
			.left-remove {
				color: #b72e09;
				border-color: #b72e09;
			}
			.edit-btn {
				.left-c-edit-btn {
					border-color: #0171db;
					color: #0171db;
				}
			}
		}
	}
	.row-action {
		height: 100%;
		position: relative;
		left: 0px;
		padding-left: 4px;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		align-items: center;
		justify-content: center;
		user-select: none;
		button {
			width: 1.5rem;
			height: 1.5rem;
			font-size: 20px;
			border: 1px solid #ccc;
			background-color: transparent;
			border-radius: 4px;
			color: #9f9f9f;
			display: flex;
			align-items: center;
			justify-content: center;
			user-select: none;
			cursor: pointer;
		}
		.left-remove {
			&:hover {
				background: #dd0000;
				color: white;
				border-color: transparent;
			}
			&:active {
				background: #810000;
				color: white;
				border-color: transparent;
			}
		}
		.edit-btn {
			position: relative;
			.left-c-edit-btn {
				width: 1.5rem;
				height: 1.5rem;
				font-size: 20px;
				border: 1px solid #ccc;
				background-color: transparent;
				border-radius: 4px;
				color: #9f9f9f;
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;
				cursor: pointer;
				&:hover {
					background: #0171db;
					color: white;
					border-color: transparent;
				}
				&:active {
					background: #005aaf;
					color: white;
					border-color: transparent;
				}
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: calc(100% + 9px);
					transform: translate(0, -50%) rotate(45deg);
					width: 12px;
					height: 12px;
					display: none;
					background: white;
					border: 0px solid #444;
					border-width: 0px 0px 1px 1px;
					z-index: 4;
				}
			}
			.left-edit-row {
				z-index: 3;
				position: absolute;
				top: 50%;
				left: 100%;
				transform: translate(15px, -50%);
				background: white;
				border: 1px solid #444;
				min-width: 100px;
				display: none;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				padding: 1rem;
				& > div {
					padding: 0;
					border: none;
					background-color: transparent;

					.column-icon {
						display: flex;
						gap: 3%;
						padding: 2%;
						align-items: center;
						width: 60px;
						height: 20px;
						i {
							height: 100%;
							background: #fdffdb;
							border: 1px solid #aaa;
							cursor: pointer;
						}
					}
					&:hover {
						outline: 2px solid #3d7f57;
						i {
							border-color: #688fac;
						}
					}
				}
			}
			& input:checked {
				& ~ .left-c-edit-btn {
					&::before {
						display: flex;
					}
				}
				& ~ .left-edit-row {
					display: flex;
				}
				& ~ .s-full {
					display: block;
				}
				& ~ .left-c-edit-btn {
					border-color: #0171db;
					background: #0171db;
					color: white;
				}
			}
		}
	}
}
[data-block='remove-context-menu'] {
	position: fixed;
	z-index: 1;
	inset: 0;
}
[data-block='context-menu'] {
	position: fixed;
	z-index: 99;
	background: white;
	box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding: 4px 0;
	& > div {
		padding: 4px 20px;
		display: flex;
		gap: 4px;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
		font-size: 0.9rem;
		& > svg {
			margin-top: 2px;
			width: 24px;
			height: 24px;
		}
		&.current-text-btn {
			color: #914b00;
			&:hover {
				background: #914b00;
				color: white;
			}
		}
		&.parent-text-btn {
			color: #b02000;
			&:hover {
				background: #b02000;
				color: white;
			}
		}
		&.delete-btn {
			color: red;
			&:hover {
				background: red;
				color: white;
			}
		}
		&.spacing-btn {
			color: #001eff;
			&:hover {
				background: #001eff;
				color: white;
			}
		}
		&.bg-img-btn {
			color: #00b57b;
			&:hover {
				background: #00b57b;
				color: white;
			}
		}
		&.clear-space-btn {
			color: #ff6a00;
			&:hover {
				background: #ff6a00;
				color: white;
			}
		}
	}
}
[data-block='left-actions'] {
	position: absolute;
	top: 0px;
	left: -30px;
	height: 100%;

	input:checked {
		& ~ .left-c-btn {
			&::before {
				display: flex;
			}
		}
		& ~ .left-add-row {
			display: flex;
		}
		& ~ .s-full {
			display: block;
		}
	}
	.left-c-btn {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 3;
		transform: translate(0, 50%);
		width: 1.5rem;
		height: 1.5rem;
		font-size: 20px;
		background: #3656a0;
		border-radius: 4px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		cursor: pointer;
		&:hover {
			background-color: #004cff;
		}
		&:active {
			background-color: #0093ff;
		}
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: calc(100% + 10px);
			transform: translate(0, -50%) rotate(45deg);
			width: 12px;
			height: 12px;
			display: none;
			background: white;
			border: 0px solid #8e8e8e87;
			border-width: 0px 0px 1px 1px;
		}
	}
	.left-add-row {
		display: none;
		box-shadow: -2px 0 10px rgba($color: #000000, $alpha: 0.1);
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: calc(100% + 12px);
		transform: translate(0, 20px);
		background: white;
		border: 1px solid #8e8e8e87;
		min-width: 100px;

		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1rem 8px 1rem 1rem;
		gap: 4px;
		button {
			padding: 4px;
			background-color: transparent;
			border: none;
			border-top: 1px solid #eee;

			.column-icon {
				display: flex;
				gap: 1px;
				padding: 2px;
				align-items: center;
				width: 100px;
				height: 25px;
				i {
					height: 100%;
					background: #ecfffd;
					border: 1px solid #aaa;
					cursor: pointer;
				}
			}
			&:hover {
				outline: 2px solid #5c8080;
				i {
					border-color: #50606c;
				}
			}
		}
	}
}
[data-block='bottom-actions'] {
	position: relative;
	margin: 10px auto;
	margin-top: 3rem;
	padding: 10px 0;
	opacity: 0.1;
	&:hover {
		opacity: 1;
	}
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80%;
		height: 2px;
		transform: translate(-50%, -50%);
		background: #aaa;
	}
	.bottom-c-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 8;
		width: 1.5rem;
		height: 1.5rem;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		font-size: 16px;
		background: #222;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			background-color: #004cff;
		}
		&:active {
			background-color: #0093ff;
		}
	}
	.bottom-add-item {
		display: none;
		box-shadow: -2px 0 10px rgba($color: #000000, $alpha: 0.1);
		position: absolute;
		z-index: 9;
		top: calc(100% + 15px);
		left: 50%;
		transform: translate(-50%, 0%);
		background: white;
		border: 1px solid #8e8e8e87;
		min-width: 100px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		padding: 1rem;
		& > div {
			padding: 4px 0;
			width: 60px;
			height: 60px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-weight: 600;
			border-radius: 4px;
			svg {
				font-size: 28px;
			}
			&:hover {
				box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.4);
			}
		}
		&--link {
			color: #093bff;
			&:hover {
				color: white;
				background-color: #093bff;
			}
		}
		&--img {
			color: #00ac5c;
			&:hover {
				color: white;
				background-color: #00ac5c;
			}
		}
		&--text {
			color: #ff7300;
			&:hover {
				color: white;
				background-color: #ff7300;
			}
		}
		&--form {
			color: #ff00ae;
			font-size: 14px;
			&:hover {
				color: white;
				background-color: #ff00ae;
			}
		}
		&::after {
			content: '';
			position: absolute;
			top: -1px;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 12px;
			height: 12px;
			display: none;
			background: white;
			border: 0px solid #8e8e8e87;
			border-width: 1px 0px 0px 1px;
		}
	}
	input:checked {
		& ~ .bottom-c-btn {
			transform: translate(-50%, -50%) rotate(45deg);
		}
		& ~ .bottom-add-item {
			display: flex;
			&::after {
				display: flex;
			}
		}
		& ~ .s-full {
			display: block;
		}
	}
}
.sun-editor {
	position: relative;
	z-index: 101;
}

.body-frame {
	position: fixed;
	z-index: 1;
	inset: 0;
	background: #2222;
}
.close-frame {
	position: fixed;
	z-index: 100;
	inset: 0;
	background: #2222;
}
.dragenter {
	outline: 2px dashed rgb(108, 187, 194);
}
.dragging {
	background: #fffdc8 !important;
	border: 1px solid #984949;
	.form-edit-item,
	.form-delete-item {
		display: none;
	}
	.form-move-item {
		background: #1f5d5821;
		color: #fff !important;
	}
}
.editing {
	border: 2px dashed rgb(237, 118, 0);
}
.form-move-item {
	opacity: 0;
	position: absolute;
	top: 0%;
	left: 0px;
	background: #fff5;
	border-radius: 4px;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: move;
	color: #ccc;
	transform: translate(0%, 0%);
}
.form-delete-item {
	position: absolute;
	top: 50%;
	right: -80px;
	border: 1px solid currentColor;
	border-radius: 4px;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(-50%);
	cursor: pointer;
	color: #e32675;
	&:hover {
		color: white;
		background: #e32675;
	}
}
.form-edit-item {
	position: absolute;
	top: 50%;
	right: -40px;
	border: 1px solid currentColor;
	border-radius: 4px;
	height: 30px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(-50%);
	cursor: pointer;
	color: #094e98;
	&:hover {
		color: white;
		background: #094e98;
	}
}
.form-branch-item {
	position: absolute;
	top: 50%;
	left: 0px;
	border: 1px solid currentColor;
	border-radius: 4px;
	height: 24px;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transform: translate(calc(-4px - 100%), -50%);
	&:hover {
		background: #fff;
		filter: contrast(200%);
		box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.25);
	}
}
.branch-items {
	position: absolute;
	top: -10px;
	left: 8px;
	background: #fff;
	border-radius: 8px;
	z-index: 99;
	width: max-content;
	max-width: 800px;
	min-width: 300px;
	box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.1), 0 1px 4px rgba($color: #000000, $alpha: 0.1),
		0 2px 10px rgba($color: #000000, $alpha: 0.3);
	padding: 10px;
	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		width: 12px;
		height: 12px;
		top: 0;
		left: 0;
		background: #fff;
		transform: translate(-7px, 20px) rotate(45deg);
		border-left: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}
	& > p {
		text-align: center;
		padding: 4px 0;
		&.menu-head {
			font-weight: 500;
			border-bottom: 1px solid #ccc;
			background: #eee;
		}
	}
}
.onClickListener {
	position: fixed;
	z-index: 90;
	inset: 0;
}
.branchChild {
	display: flex;
	align-items: center;
	gap: 20px;
	border-bottom: 1px solid #eee;
	border-left: 4px solid #aaa;
	margin: 4px auto;
	padding: 2px 10px;
	&:hover {
		background: rgba(205, 195, 209, 0.2);
	}
}
.form-branch-addToChild {
	cursor: pointer;
	height: 24px;
	width: 24px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #5f9809;
	&:hover {
		color: white;
		background: #5f9809;
	}
}
.form-branch-removeFromChild {
	cursor: pointer;
	height: 24px;
	width: 24px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #980928;
	&:hover {
		color: white;
		background: #980928;
	}
}
[data-type='form'] {
	&:hover {
		outline: 2px solid #001eff !important;
	}
}
[data-branch] {
	&:hover {
		opacity: 1 !important;
	}
}
.workflow {
	&__public {
		&:hover {
			background: #5f980933 !important;
		}
	}
	&__un-public {
		&:hover {
			background: #fd620133 !important;
		}
	}
}
.csv-download-btn {
	border: none;
	background: #388e3c;
	color: #fff;
	font-size: 1rem;
	font-weight: bold;
	padding: 0.4rem 2rem;
	text-align: center;
	min-width: 200px;
	border-radius: 4px;
	box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.3);
	cursor: pointer;
	&:hover {
		box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.2);
	}
	&:active {
		background: #2a6f2e;
	}
}
[data-allow-js][data-type] {
	width: 100%;
	height: 200px;
	border: 2px dashed #ccc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	&::before {
		content: 'フォームエリア';
		font-size: x-large;
		font-weight: bold;
	}
	&::after {
		content: '詳しくはプレビュー画面へ';
	}
}
